/* Variables */
:root {
  --link_hover: #0f6aa2;
  --main-color: #0f6aa2;
  --text-color: #707070;
  --sec-color: #ededed;
  --gray-color: #4d4d4d;
  --danger-color: #dc3545;
  --unnamed-color-777777de: #777777de;
  --unnamed-color-18c5bf: #18c5bf;
  --unnamed-color-ffffffde: #ffffffde;
  --unnamed-color-4a4848: #4a4848;
  --unnamed-color-d8d4d4: #d8d4d4;
  --unnamed-color-f2f2f2: #f2f2f2;
  --unnamed-color-545454: #545454;
  --unnamed-color-212121de: #212121de;
  --unnamed-color-000000de: #000000de;
  --unnamed-color-000000: #000000;
  --light-color: #ffffff;
  --bckg-color: #f8f8f8;
  --font-size: 14px;
  --font-weight: 400;
  --font-family: "Roboto", "Droid Arabic Kufi", "Arial", sans-serif;
}
